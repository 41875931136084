import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useLogger } from "@wisual/logger";

const Canvas = React.lazy(
  () => import("./canvas/Canvas" /* webpackChunkName: "Canvas" */)
);
const LoginPage = React.lazy(
  () => import("./login/LoginPage" /* webpackChunkName: "LoginPage" */)
);
const Stories = React.lazy(() =>
  import("./stories").then((m) => ({ default: m.Stories }))
);
const AuthManagement = React.lazy(() => import("./auth-management"));

function App() {
  const logger = useLogger("App");
  useEffect(() => {
    logger.info("Wisual started");
  }, [logger]);

  return (
    <div
      className="App"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        // @ts-ignore
        "--background":
          typeof wisualShellAPI === "undefined"
            ? "rgba(46, 42, 46, 1.00)"
            : "rgba(46, 42, 46, 0.03)",
      }}
    >
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <h1 style={{ margin: "0 auto" }}>Loading...</h1>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/patches/:id" element={<Canvas />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/auth-management" element={<AuthManagement />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
